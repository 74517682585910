import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import BlockTitle from "./block-title";
import blockTitleCircle from "../images/logo.png"
import TeamMemberOne from "../images/Team_Member_SIMON.png";
import TeamMemberTwo from "../images/Team_Member_ANNBEL.png";
import TeamMemberThree from "../images/Team_Member_A_R.png";
import BlockTitleRoadmap from "./block-title-roadmap";
const TheTeam = () => {
  return (
    <section className="services-style-one" id="team">
      <Container>
        <BlockTitle
          textAlign="center"
          image={blockTitleCircle}
          title={`THE TEAM`}
        />
        <Row>
            <Col lg={4} md={6} sm={12}>
              <div className="team-style-one block-title text-center" >
                <img src={TeamMemberOne} alt="team"  style={{width: "100%"}}/>
                <h3 style={{marginTop: 20, fontSize: 45}}>SIMON</h3>
                <h4 style={{marginTop: 20, fontSize: 20}}>FOUNDER, CREATIVE DIRECTOR &amp; ARTIST</h4>
                <p style={{margin: 20, color: "white"}}>The things you used to own, now they own you.</p>
              </div>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <div className="team-style-one block-title text-center">
                <img src={TeamMemberTwo} alt="team" style={{width: "100%"}} />
                <h3 style={{marginTop: 20, fontSize: 45}}>ANNBEL</h3>
                <h4 style={{marginTop: 20, fontSize: 20}}>ARTIST &amp; PROJECT MANAGER</h4>
                <p style={{margin: 20,color: "white"}}>Making visible the invisible.</p>
              </div>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <div className="team-style-one block-title text-center">
                <img src={TeamMemberThree} alt="team" style={{width: "100%"}} />
                <h3 style={{marginTop: 20, fontSize: 45}}>A.R</h3>
                <h4 style={{marginTop: 20, fontSize: 20}}>SOCIAL MEDIA &amp; MARKETING</h4>
                <p style={{margin: 20, color: "white"}}>In Tyler we trust.</p>
              </div>
            </Col>
        </Row>
      </Container>
    </section>
  );
};

export default TheTeam;
