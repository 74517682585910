import React from "react";
import { Container} from "react-bootstrap";
import BlockTitle from "./block-title";
import blockTitleCircle from "../images/logo.png"

const About = () => {
  return (
    <section className="about-style-one" id="about">
      <Container>
        <BlockTitle
          textAlign="center"
          image={blockTitleCircle}
          title={`ABOUT`}
        />
        <p style={{textAlign: "center", fontSize: "25px", marginTop: 30}}>Scar Kids Arcade Runaways is a generative NFT art collectible project of 9.779 unique, digitally hand-drawn avatars released on the Ethereum blockchain.</p>
        <p style={{textAlign: "center", fontSize: "25px", marginTop: 30}}>The Scar Kids are Gen X Pop-culture childs, raised by TV, music, books, films and Video Games from the 80’s and 90’s.</p>
        <p style={{textAlign: "center", fontSize: "25px", marginTop: 30}}>S.K.A.R. is a travel back in time. Choose your own Scar Kid and join the Arcade Runaways clan.</p>
      </Container>
    </section>
  );
};

export default About;
