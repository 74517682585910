import React from "react"

const BlockTitleRoadmap = ({ textAlign, image, title }) => {
  return (
    <div className={`block-title text-${textAlign}`}>
      <h2 style={{color: "yellow", fontSize: 150}}>{title}</h2>
    </div>
  )
}

export default BlockTitleRoadmap
