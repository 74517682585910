import React from "react";
import Layout from "../components/layout";
import HeaderOne from "../components/header-one";
import BannerOne from "../components/banner-one";
import About from "../components/about";
import Footer from "../components/footer";
import Seo from "../components/seo";
import Carousel from "../components/carousel";
import Team from "../components/theteam";
import Faq from "../components/faq";
import Roadmap from "../components/roadmap";
import { MoralisProvider } from "react-moralis";

const Home = () => {
  return (
    <Layout pageTitle="SCAR KIDS">
      <Seo title="SCAR KIDS" />
      <HeaderOne />
      <BannerOne />
      <About />
      <Carousel />
      <Roadmap />
      <Team />
      <Faq />
      <Footer />
    </Layout>
  )
}

export default Home
