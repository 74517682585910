import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import BlockTitleRoadmap from "./block-title-roadmap";
import blockTitleCircle from "../images/logo.png"

const Roadmap = () => {
  return (
    <section className="roadmap" id="roadmap" style={{paddingBottom: "1200px"}}>
      <Container>
        <BlockTitleRoadmap
          textAlign="center"
          image={blockTitleCircle}
          title={`ROADMAP`}
        />
        <Row>
            <Col>
              <div className="roadmap-attack" style={{paddingTop: "250px", marginTop: 50}}>
                <h2 style={{marginTop: 140, textAlign: "left", fontSize: 50, width: "450px"}}>Q3</h2>
                <h2 style={{textAlign: "center", fontSize: 60, width: "450px"}}>THE ATTACK</h2>
                <p style={{margin: 20, fontSize: 22, width: "auto"}}>- Build community.</p>
                <p style={{margin: 20, fontSize: 22, width: "auto"}}>- Presale of 1024 Scar Kids.</p>
                <p style={{margin: 20, fontSize: 22, width: "auto"}}>- 3ETH Raffle for the 1024 OG Holders.</p>
                <p style={{margin: 20, fontSize: 22, width: "auto"}}>- AIRDROP: 5 Scar Kids randomly airdropped between the 512 OG holders.</p>
              </div>
            </Col>
            <Col></Col>
        </Row>
        <Row>
            <Col></Col>
            <Col>
              <div className="roadmap-disco" style={{paddingTop: "250px", marginTop: 50}}>
                <h2 style={{marginTop: 180, textAlign: "left", fontSize: 50, width: "450px"}}>Q3</h2>
                <h2 style={{textAlign: "center", fontSize: 60, width: "450px"}}>THE LAB</h2>
                <p style={{margin: 20, fontSize: 22, width: "auto"}}>- Public sale.</p>
                <p style={{margin: 20, fontSize: 22, width: "auto"}}>- Community wallet: 5ETH.</p>
                <p style={{margin: 20, fontSize: 22, width: "auto"}}>- Weekly Treasure Hunt.</p>
                <p style={{margin: 20, fontSize: 22, width: "auto"}}>- S.K.A.R. Foundation (learn more about on our Discord channel and
Twitter).</p>
              </div>
            </Col>
        </Row>
        <Row>
            <Col>
              <div className="roadmap-floppy" style={{paddingTop: "250px", marginTop: 50}}>
                <h2 style={{marginTop: 140, textAlign: "left", fontSize: 50, width: "450px"}}>Q4</h2>
                <h2 style={{textAlign: "center", fontSize: 60, width: "450px"}}>THE CLUB</h2>
                <p style={{margin: 20, fontSize: 22, width: "auto"}}>- Community wallet: 10ETH + 50% of secondary sales.</p>
                <p style={{margin: 20, fontSize: 22, width: "auto"}}>- Purchase Metaverse Land and build the Arcade Runaways Club.</p>
                <p style={{margin: 20, fontSize: 22, width: "auto"}}>- AIRDROP: 10 Special Scar Kids randomly airdropped for holders.</p>
              </div>
            </Col>
            <Col></Col>
        </Row>
        <Row>
            <Col></Col>
            <Col>
              <div className="roadmap-nintendo" style={{paddingTop: "250px", marginTop: 50}}>
                <h2 style={{marginTop: 200, textAlign: "left", fontSize: 50, width: "450px"}}>Q4</h2>
                <h2 style={{textAlign: "center", fontSize: 60, width: "450px"}}>THE ARCADES</h2>
                <p style={{margin: 20, fontSize: 22, width: "auto"}}>- Community wallet: 10ETH.</p>
                <p style={{margin: 20, fontSize: 22, width: "auto"}}>- First projects of the S.K.A.R. Foundation.</p>
                <p style={{margin: 20, fontSize: 22, width: "auto"}}>- Metaverse Arcade Runaways Club big opening.</p>
                <p style={{margin: 20, fontSize: 22, width: "auto"}}>- Weekly games and contest.</p>
              </div>
            </Col>
        </Row>
        <Row>
            <Col>
              <div className="roadmap-game" style={{paddingTop: "250px"}}>
                <h2 style={{marginTop: 200, textAlign: "left", fontSize: 50, width: "450px"}}>Q4</h2>
                <h2 style={{textAlign: "center", fontSize: 60, width: "450px"}}>IT'S ALIVE!!</h2>
                <p style={{margin: 20, fontSize: 22, width: "auto"}}>- Free Mint project for holders: Scar Kids Player Two.</p>
                <p style={{margin: 20, fontSize: 22, width: "auto"}}>- Real life Merch.</p>
                <p style={{margin: 20, fontSize: 22, width: "auto"}}>- Scar Kids Defenders original game.</p>
              </div>
            </Col>
            <Col></Col>
        </Row>
      </Container>
    </section>
  );
};

export default Roadmap;
