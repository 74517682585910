import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import ArrowFAQ from "../../images/arrow-faq.png";
const AccordionCard = props => {
  const [status, setStatus] = useState(props.status)
  const handleClick = () => {
    setStatus(!status)
  }
  return (
    <div className={`accrodion ${status === true ? "active" : ""} faq-style-two`}>
      <div className="accrodion-inner  ">
        <div
          className="accrodion-title"
          onClick={handleClick}
          onKeyDown={handleClick}
          role="button"
          tabIndex={0}
        >
          <Row>
            <Col sm={10} >
              < h2 style={{color: "yellow"}}>{props.title}</h2>
            </Col>
            <Col sm={2}>
              <img src={ArrowFAQ} alt="arrow"/>
            </Col>
          </Row>
        </div>
        {status === true ? (
          <div className="accrodion-content">
            <div className="inner">
              <h4 style={{color: "yellow"}}>{props.content}</h4>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default AccordionCard
