import React from "react"
import BackgroundBanner from "../images/CUPULA _CIELO.jpg"

const BannerOne = () => {
  return (
    <section className="banner-style-one" id="banner">
      <img  className="banner-style-one" src={BackgroundBanner} alt="SKAR KIDS" />
    </section>
  )
}

export default BannerOne
