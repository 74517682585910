import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import SL1 from "../images/SCROLL_1.png";
import SL2 from "../images/SCROLL_2.png";
import SL3 from "../images/SCROLL_3.png";
import SL4 from "../images/SCROLL_4.png";
import SL5 from "../images/SCROLL_5.png";
import SL6 from "../images/SCROLL_6.png";
import SL7 from "../images/SCROLL_7.png";
import SL8 from "../images/SCROLL_8.png";
import SL9 from "../images/SCROLL_9.png";
import SL10 from "../images/SCROLL_10.png";
import SL11 from "../images/SCROLL_11.png";
import GameMachine from "../images/arcade.gif";
import MintButton from "../images/mint-button.png";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 1 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  }
};
const carousel = () => {
return( 
    <section className="collection-style-one" id="collection">
      <div class="container">
        <div class="block-title text-center">
          <h2 style={{color: "yellow"}}>COLECTION</h2>
        </div>
      </div>
      <Carousel
        swipeable={false}
        draggable={false}
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        autoPlaySpeed={4000}
        keyBoardControl={true}
        customTransition="all .5"
        transitionDuration={500}
        containerClass="carousel-container"
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
      >      
          <img src={SL1} alt="SCAR 1" style={{height: "100%", width: "100%", padding: 0, margin: 0}} />
          <img src={SL2} alt="SCAR 2" style={{height: "100%", width: "100%", padding: 0, margin: 0}} />
          <img src={SL3} alt="SCAR 3" style={{height: "100%", width: "100%", padding: 0, margin: 0}} />
          <img src={SL4} alt="SCAR 4" style={{height: "100%", width: "100%", padding: 0, margin: 0}} />
          <img src={SL5} alt="SCAR 5" style={{height: "100%", width: "100%", padding: 0, margin: 0}} />
          <img src={SL6} alt="SCAR 6" style={{height: "100%", width: "100%", padding: 0, margin: 0}} />
          <img src={SL7} alt="SCAR 7" style={{height: "100%", width: "100%", padding: 0, margin: 0}} />
          <img src={SL8} alt="SCAR 8" style={{height: "100%", width: "100%", padding: 0, margin: 0}} />
          <img src={SL9} alt="SCAR 9" style={{height: "100%", width: "100%", padding: 0, margin: 0}} />
          <img src={SL10} alt="SCAR 10" style={{height: "100%", width: "100%", padding: 0, margin: 0}} />
          <img src={SL11} alt="SCAR 11" style={{height: "100%", width: "100%", padding: 0, margin: 0}} />
          
      </Carousel>
      <div style={{margin: "auto", marginTop: "80px", textAlign: "center"}}>
          <a href="/mint">
            <img src={MintButton} alt="mint button" />
          </a>
      </div>
      <Container style={{ marginTop: 80}}>
        <Row>
          <Col>
            <div>
            <h2 style={{color: "yellow", textAlign: "left", fontSize: "70px", width: "100%", marginBottom: "200px"}}>THE STORY</h2>
              <img src={GameMachine} style={{padding: "0px", margin: -140}} alt="game-machine" />
            </div>        
          </Col>
          <Col>
            <div class="container">
              <div class="block-title" style={{ marginTop: 150, width: "auto"}}>
                <p style={{color: "yellow", textAlign: "center", marginTop: 20, width: "auto", fontSize: "22px"}}>
                In 1981 the young programmer Cassandra Addis developed the arcade
                game Scar Kids Defenders. In the game the Earth was attacked by Alien Spiders from Mars and the
                shady Dr Shelley created the Scar Kids as a last resort to fight them and
                stop the invasion.
                </p>
                <p style={{color: "yellow", textAlign: "center", marginTop: 20, width: "auto", fontSize: "22px"}}>
                The game was a massive hit worldwide due to the amazing interaction
                between the players and the characters.
                Each player could customize their Scar Kid and the game recorded their
                personal choices forever.
                Every player and every Scar Kid were different.
                Every kid wanted to be a Scar Kid.
                </p>
                <p style={{color: "yellow", textAlign: "center", marginTop: 20, width: "auto", fontSize: "22px"}}>
                The Scar Kids became an Icon . . . and a franchise under the greedy control
                of Wollstonecraft Inc.
                </p>
                <p style={{color: "yellow", textAlign: "center", marginTop: 20, width: "auto", fontSize: "22px"}}>
                Different versions of the game landed in our homes. From Shoot ´Em Up to
                platform games, from Graphic Adventures to Beat ´Em Up, they were in
                everything.
                </p>
                <p style={{color: "yellow", textAlign: "center", marginTop: 20, width: "auto", fontSize: "22px"}}>
                In the mid ´90s Cassandra felt she had strayed from her original purpose
                and ran away with the codes.
                Wollstonecraft Inc. shut down the franchise and there were no more games.
                There were no more Scar Kids.
                </p>
                <p style={{color: "yellow", textAlign: "center", marginTop: 20, width: "auto", fontSize: "22px"}}>
                But the Scar Kids were hidding a secret in its design: Cassandra had coded
                a learning algorithm on every Scar Kid.
                Forgotten on memory cards, floppy disk and hard drives, the Scar Kids
                continued growing and learning.
                Now Cassandra felts the time has come to release all those Scar Kids and
                she have found the perfect way…the Ethereum blockchain.
                </p>
                <h2 style={{color: "yellow", textAlign: "center"}}>The Scar Kids Lives again!!</h2>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
);}

export default carousel;