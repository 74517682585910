import React, { useState, useEffect, useRef } from "react";
import { Link } from "gatsby";
import NavLinks from "./nav-links";
import logoImage from "../images/logo.png";
import MintButton from "../images/mint-button.png";
import { useMoralis } from "react-moralis";

const HeaderOne = () => {
  const [sticky, setSticky] = useState(false)
  const menuEl = useRef(null)

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const handleScroll = () => {
    if (window.scrollY > 70) {
      setSticky(true)
    } else if (window.scrollY < 70) {
      setSticky(false)
    }
  }

  return (
    <header className="site-header header-one">
      <nav
        className={`navbar navbar-expand-lg navbar-light header-navigation stricky  ${
          sticky === true ? "stricky-fixed" : " "
        }`}
      >
        <div className="container clearfix">
          <div className="logo-box clearfix">
            <Link to="/" className="navbar-brand">
              <img src={logoImage} alt="Scar Kids" style={{width: 70}} />
            </Link>
            <button
              className="menu-toggler"
              onClick={e => {
                e.preventDefault()
                menuEl.current.classList.toggle("active")
              }}
            >
              <span className="fa fa-bars"></span>
            </button>
          </div>

          <div className="main-navigation" ref={menuEl}>
            <NavLinks />
          </div>
          <Link to="/mint" className="navbar-brand">
            <div className="right-side-box"  style={{marginRight: "auto"}}>
                <img src={MintButton} alt="mint button"  style={{width: 85}}/>
            </div>
          </Link>
          </div>
      </nav>
    </header>
  )
}

export default HeaderOne
