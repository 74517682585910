import React from "react"
import { Container } from "react-bootstrap"
import BlockTitle from "./block-title"
import AccordionCard from "./cards/accordion-card"
import blockTitleCircle from "../images/logo.png"

const ACCORDION_DATA = [
  {
    status: true,
    content:
      "Scar Kids Arcade Runaways is a generative NFT art collectible project of 9.779 unique, digitally hand-drawn avatars released on the Ethereum blockchain.",
    title: "What are the Scar Kids?",
  },
  {
    status: false,
    content:
    "Every Scar Kid gives you access to the Arcade Runaways Club. Metaverse and IRL membership.",
    title: "What is the utility?",
  },
  {
    status: false,
    content:
    "Yes we have a Plan: The S.K.A.R. Foundation. A project of patronage for members of our community who have ideas or projects and needs financial and team support. We are here for them and together we got their backs.",
    title: "Do you have a Plan? What´s The Plan?",
  },
  {
    status: false,
    content:
    "TBA, follow us on Discord and Twitter.",
    title: "Wen Presale? Wen mint? Whitelist?",
  },
  {
    status: false,
    content:
    "You are free to do anything with your Scar Kid. You have exclusive ownership as long as you own the NFT.",
    title: "What can I do with my Scar Kid?",
  }
]

const Faq = () => {
  return (
    <section className=" faq-style-one" id="faq">
      <Container>
        <BlockTitle
          textAlign="center"
          image={blockTitleCircle}
          title={`FAQ`}
        />
        <div className="accrodion-grp faq-accrodion">
          {ACCORDION_DATA.map(({ status, title, content }, index) => (
            <AccordionCard
              key={`accordion-card-${index}`}
              status={status}
              title={title}
              content={content}
            />
          ))}
        </div>
      </Container>
    </section>
  )
}

export default Faq
