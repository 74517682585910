import React, { useRef } from "react"
import { Link as ScrollLink } from "react-scroll"
import Discord from "../images/discord.png"
import { Link } from "gatsby";
import MintButton from "../images/mint-button.png";

const NavLinks = () => {
  const subMenuEl = useRef(null)
  return (
    <ul className="one-page-scroll-menu navigation-box" ref={subMenuEl}>
      <li>
        <ScrollLink
          activeClass="current"
          to="collection"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          <h4>COLLECTION</h4>
        </ScrollLink>
      </li>
      <li>
        <ScrollLink
          activeClass="current"
          to="about"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          <h4>HISTORY</h4>
        </ScrollLink>
      </li>
      <li>
        <ScrollLink
          activeClass="current"
          to="roadmap"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          <h4>ROADMAP</h4>
        </ScrollLink>
      </li>
      <li>
        <ScrollLink
          activeClass="current"
          to="team"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          <h4>TEAM</h4>
        </ScrollLink>
      </li>
      <li>
        <ScrollLink
          activeClass="current"
          to="faq"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          <h4>FAQ</h4>
        </ScrollLink>
      </li>
      <li>
        <div className="footer-widget">
          <div className="social-block">
            <a href="#none">
              <i className="fa fa-twitter" style={{color: "white"}}></i>
            </a>
            <a href="#none">
              <i className="fa fa-instagram" style={{color: "white"}}></i>
            </a>
            <a href="#none">
              <img src={Discord} className="fa" style={{width: 16, color: "white"}} alt="discord"/>
            </a>
            <a href="#none">
              <i className="fa fa-medium" style={{color: "white"}}></i>
            </a>
          </div>
        </div>
      </li>
    </ul>
  )
}

export default NavLinks
